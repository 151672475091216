/* BodyContouringPage.css */
.body-contouring-page-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: justify;
  }
  
  .before-after-container {
    text-align: center;
  }
  
  .image-pair {
    margin-bottom: 20px;
  }
  
  .before-image, .after-image {
    width: 48%; /* Adjust the width as needed */
    margin: 1%; /* Small gap between images */
    display: inline-block; /* Place images side by side */
    box-shadow: 0 2px 5px rgba(0,0,0,0.2); /* Optional shadow for depth */
  }
  
  /* Responsive adjustments if needed */
  @media (max-width: 768px) {
    .before-image, .after-image {
      width: 100%; /* Stack images on smaller screens */
      margin-bottom: 10px;
    }
  }

  .image-section {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .before-after-image {
    max-width: 100%; /* Adjust based on your layout */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px;
  }
  
  figure {
    display: inline-block; /* Centers the figure in the parent div if needed */
  }
  
  figcaption {
    margin-top: 10px; /* Space between image and caption */
    font-style: italic; /* Differentiate the caption */
    color: #555; /* Subdued color for the caption */
    max-width: 80%; /* Optional width styling */
    margin: auto; /* Center the caption if max-width is set */
  }
  