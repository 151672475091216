/* ReflexologyPage.css */
.reflexology-page-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  .reflexology-content {
    flex: 1;
    max-width: 600px;
    margin-right: 20px;
  }
  
  .reflexology-content h1, .reflexology-content h2 {
    color: #333;
  }
  
  .reflexology-content p {
    text-align: justify;
    margin-bottom: 20px;
  }
  
  .benefits-list {
    list-style-type: none;
    padding-left: 0;
  }
  
  .benefits-list li::before {
    content: '•';
    color: #333;
    margin-right: 8px;
  }
  
  .reflexology-image-container {
    flex: 1;
    text-align: right;
  }
  
  .reflexology-image-container img {
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .reflexology-page-container {
      flex-direction: column;
    }
  
    .reflexology-content {
      max-width: 100%;
      margin-right: 0;
    }
  
    .reflexology-image-container {
      text-align: center;
      order: -1; /* This will put the image above the text on small screens */
    }
  }
  