.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* InfoBox.css */
.info-box {
  border-left: 4px solid #007bff; /* A blue accent on the left for attention */
  background-color: #f9f9f9; /* A light background to stand out */
  padding: 20px;
  margin-bottom: 20px; /* Space it out from other content */
  text-align: left;
}

.info-box-title {
  margin-bottom: 10px;
  color: #333; /* Slightly stronger color for the title */
  font-weight: bold;
}

.warning-box {
  border-left: 4px solid red;
  background-color: #f9f9f9; /* A light background to stand out */
  padding: 20px;
  margin-bottom: 20px; /* Space it out from other content */
  text-align: left;
}

.welcome-section {
  text-align: center;
  padding: 50px 20px;
  background-size: cover;
  background-position: center;
  color: #fff; /* Or any color that stands out against your image */
}

.welcome-section h1 {
  font-size: 3rem; /* Larger font size for the title */
  margin-bottom: 20px;
  color: #febd69; /* A color that stands out, adjust as needed */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for legibility */
}

.welcome-section p {
  font-size: 1.25rem; /* Slightly larger font size for the text */
  max-width: 800px; /* To prevent the line from being too long on large screens */
  margin: auto; /* Centers the text block */
  background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background for legibility */
  padding: 20px;
  border-radius: 8px; /* Optional: rounded corners */
}
