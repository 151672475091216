/* contactpage.css */
main header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  header h1 {
    color: #333;
  }
  
  header p {
    color: #555;
  }
  
  form {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    margin: 0 auto;
  }
  
  form label {
    margin-top: 10px;
  }
  
  form input[type="text"],
  form input[type="email"],
  form select,
  form textarea {
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form textarea {
    min-height: 40px;
  }

  form button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  