/* src/components/HealthValues.css */
.health-values {
    background-color: #f8f8f8;
    padding: 40px;
    font-family: 'Arial', sans-serif;
  }
  
  .health-values h2 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .health-values-content {
    display: flex;
    flex-wrap: wrap; /* Enable wrapping */
    justify-content: center; /* Center cards on the page */
    gap: 20px; /* Spacing between cards */
  }
  
  .health-value {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #ffffff;
    border-radius: 4px;
    width: calc(33.33% - 40px); /* Three cards per row, minus the gap */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .health-value .icon {
    font-size: 50px; /* Adjust size as needed */
    margin-bottom: 10px;
  }
  
  .health-value h3 {
    color: #444;
    margin-bottom: 10px;
  }
  
  .health-value p {
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .health-value {
      width: calc(50% - 40px); /* Two cards per row on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .health-value {
      width: 100%; /* Full width for very small screens */
    }
  }
  