/* src/components/MeetOurPractitioner.css */
.practitioner {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif; /* Replace with the font of your choice */
  }
  
  .practitioner h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .practitioner-details {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  
  .practitioner-photo {
    border-radius: 50%;
    width: 150px; /* Adjust size as needed */
    height: 150px; /* Adjust size as needed */
    object-fit: cover; /* This will ensure the image covers the area nicely */
  }
  
  .practitioner-info {
    max-width: 500px; /* Adjust width as needed */
    text-align: left;
  }
  
  .practitioner-info h3 {
    font-size: 1.5em;
    color: #444;
  }
  
  @media (max-width: 768px) {
    .practitioner-details {
      flex-direction: column;
    }
  
    .practitioner-info {
      text-align: center;
    }
  }
  