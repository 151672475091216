/* pricelist.css */
.price-list-container {
  display: flex;
  flex-direction: column; /* Stack the cards and footer text vertically */
  align-items: center; /* Center-align the items vertically */
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center; /* Center the cards */
}

.price-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 300px; /* Set the width of the cards */
  text-align: center;
  margin-bottom: 20px; /* Space at the bottom of each card */
}

.price-card h3 {
  color: #333;
  margin-bottom: 10px;
}

.price-description {
  color: #666;
  font-size: 0.9rem;
}

.footer-text {
  text-align: center;
  padding: 20px;
  width: 100%; /* Full width */
  font-size: 1rem; /* Adjust font size as needed */
  color: #666; /* Footer text color */
  /* Additional styling if needed */
}
