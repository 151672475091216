/* reikipage.css */
.reiki-page-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .intro-section, .reiki-experience-section, .closing-remark-section {
    margin-bottom: 20px;
  }
  
  .reiki-image-container {
    text-align: center; /* Center the image if it's not full width */
    margin: 20px 0;
  }
  
  .reiki-image-container img {
    max-width: 100%; /* Make image responsive */
    height: auto;
  }
  
  .benefits-section {
    margin-top: 20px;
  }
  
  .benefits-list {
    list-style-type: none; /* Remove default bullets */
    padding: 0;
  }
  
  .benefits-list li:before {
    content: '• '; /* Custom bullet */
    color: #333; /* Bullet color */
    font-weight: bold;
    /* More styling if necessary */
  }
  
  /* Add media queries for responsiveness as needed */
  