/* src/components/Testimonials.css */
.testimonials {
    background: #f8f8f8;
    text-align: center;
    padding: 40px;
    font-family: 'Arial', sans-serif; /* You can change the font to something more fancy if you like */
  }
  
  .testimonials h2 {
    font-size: 2em;
    color: #333;
    margin-bottom: 20px;
  }
  
  .testimonial-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start; /* Align items to the start to cater for different text lengths */
    flex-wrap: wrap; /* Wrap items to next line on small screens */
  }
  
  .testimonial {
    background: #ffffff;
    padding: 20px;
    margin: 0 10px 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative;
    flex: 0 0 calc(25% - 20px); /* Take up one-third of container width minus margin */
    display: flex;
    flex-direction: column;
    height: 150px; /* Set a fixed height */
  }
  
  .testimonial p {
    flex-grow: 1; /* Allow the paragraph to grow and fill the space */
    overflow: hidden; /* Hide overflow */
  }
  
  .testimonial:before {
    content: '“';
    font-size: 50px;
    color: gold;
    position: absolute;
    top: -0px;
    left: 20px;
  }
  
  .testimonial footer {
    text-align: right;
    color: #555;
    margin-top: 10px;
    font-style: italic;
  }
  
  @media (max-width: 1024px) {
    .testimonial {
      flex: 0 0 calc(50% - 20px); /* Adjust width to 50% for medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-wrapper {
      flex-direction: column;
      align-items: center;
    }
  
    .testimonial {
      flex: 0 0 80%; /* Full width minus padding on small screens */
      height: auto; /* Allow height to adjust to content on small screens */
      margin-bottom: 40px;
    }
  }
  
  /* Add additional media queries and styles as needed */
  