/* MassagePage.css */
.massage-page-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 20px;
  }
  
  .text-content, .image-content {
    flex: 1;
    min-width: 300px; /* Minimum width before wrapping */
    margin: 10px;
  }
  
  .text-content h1 {
    font-size: 2em;
    color: #333;
  }
  
  .text-content p {
    text-align: justify;
    color: #666;
    margin: 10px 0;
  }
  
  .image-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center images in the column */
  }
  
  .image-content img {
    width: 100%; /* Make images responsive */
    margin-bottom: 20px; /* Space between images */
    max-width: 400px; /* Maximum width of images */
  }
  
  @media (max-width: 768px) {
    .massage-page-container {
      flex-direction: column;
    }
    
    .image-content img {
      max-width: 100%; /* Full width on smaller screens */
    }
  }
  