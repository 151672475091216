/* src/components/footer.css */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

.footer {
    color: #ffffff;
    background-color: #222;
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
    font-family: 'Lato', sans-serif;
    margin-top: 10px;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer-section h3 {
    font-weight: 700;
    margin-bottom: 10px;
}

.footer-section p {
    font-weight: 400;
    margin: 5px 0;
    text-align: center;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin-bottom: 20px;
    }
}

.footer-section.follow-us a {
    color: #3b5998; /* Facebook blue color */
    text-decoration: none;
    font-size: 1.2em; /* Adjust the size as needed */
  }
  
  .footer-section.follow-us a:hover {
    text-decoration: underline;
  }
  
  .footer-section.follow-us i {
    margin-right: 8px;
  }
  
