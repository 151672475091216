/* src/components/header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: url('../images/banner1.jpeg'); /* Adjust path as needed */
    background-size: cover;
    background-position: center;
    padding: 20px; /* Adjust padding as needed */
    color: #ffffff; /* Ensures text is visible on your banner */
    margin-bottom: 10px; /* Adjust margin as needed */
}

.burger-menu span {
    cursor: pointer;
    font-size: 24px;
    color: #ffffff; /* Ensures the burger menu icon is visible */
}

.site-name a {
    text-decoration: none;
    color: #ffffff; /* Adjust to fit your design */
    font-size: 24px; /* Adjust size as needed */
}


.burger-menu {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.burger-bar {
    height: 3px;
    width: 25px;
    background-color: #fff;
}

.drawer-menu {
    position: fixed;
    top: 0;
    left: -100%; /* Start offscreen */
    width: 250;
    height: 100%;
    background: #fff;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Hide overflow content */
    transition: left 0.3s ease;
    z-index: 1000; /* Ensure it's above other content */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures footer is at the bottom */
}

.menu-links {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.drawer-menu.open {
    left: 0; /* Slide in */
}

.drawer-menu a {
    padding: 10px 0;
    text-decoration: none;
    color: #333;
    transition: color 0.3s ease;
}

.drawer-menu a:hover {
    color: #007bff;
}


.drawer-footer {
    padding: 20px;
    background: #f8f8f8;
    width: 250px; /* Match the width of the drawer */
    text-align: center;
    color: #333;
}

.drawer-footer .drawer-site-name {
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.drawer-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    font-size: 36px; /* Adjust size as needed */
    /* padding: 2px; */
    cursor: pointer;
}